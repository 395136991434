import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'aop-app-alerts',
  templateUrl: './app-alerts.component.html',
  styleUrls: ['./app-alerts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class AppAlertsComponent implements OnInit {

  constructor() { }

  @Input() emailVerified: any;

  ngOnInit() {
  }
}
