<section class="nav-group">
  <input id="quickActions" type="checkbox">
  <label for="quickActions">Quick Actions</label>
  <ul class="nav-list">
    <li><a class="nav-link" routerLink="/contacts/add" routerLinkActive="active">Add Contact</a></li>
    <!-- <li><a class="nav-link">Add Reminder</a></li>
    <li><a class="nav-link">Enter Activity</a></li> -->
    <li><span class="nav-link aop-quickaction-disabled">Add Reminder</span></li>
    <li><span class="nav-link aop-quickaction-disabled">Enter Activity</span></li>
    <li><a class="nav-link" routerLink="/contacts/view/active" routerLinkActive="active">Manage Contacts</a></li>
    <!-- <li><a class="nav-link">Weekly Worksheet</a></li>
    <li><a class="nav-link">Business Tracker</a></li>
    <li><a class="nav-link">Set Goals</a></li> -->
    <li><span class="nav-link aop-quickaction-disabled">Weekly Worksheet</span></li>
    <li><span class="nav-link aop-quickaction-disabled">Business Tracker</span></li>
    <li><span class="nav-link aop-quickaction-disabled">Set Goals</span></li>
  </ul>
</section>
