export abstract class Constants {
  static readonly contactTypes = [
    {label: 'Select...', value: null}, // placeholder for select form
    {label: 'Buyer', value: 'Buyer'},
    {label: 'Seller', value: 'Seller'},
    {label: 'Buyer + Seller', value: 'Buyer + Seller'},
    {label: 'Prospect', value: 'Prospect'},
    {label: 'Past Client', value: 'Past Client'},
    {label: 'Other', value: 'Other'}
  ];
  static readonly socialNetworks = [
    {label: 'Website', value: 'Website'},
    {label: 'Facebook', value: 'Facebook'},
    {label: 'Instagram', value: 'Instagram'},
    {label: 'LinkedIn', value: 'LinkedIn'},
    {label: 'Pinterest', value: 'Pinterest'},
    {label: 'Skype', value: 'Skype'},
    {label: 'Snapchat', value: 'Snapchat'},
    {label: 'Twitter', value: 'Twitter'},
    {label: 'Other', value: 'Other'},
  ];
  static readonly emailLabels = [
    {label: 'Home', value: 'Home'},
    {label: 'Work', value: 'Work'},
    {label: 'Other', value: 'Other'},
  ];
  static readonly phoneLabels = [
    {label: 'Mobile', value: 'Mobile'},
    {label: 'Home', value: 'Home'},
    {label: 'Work', value: 'Work'},
    {label: 'Fax', value: 'Fax'},
    {label: 'Other', value: 'Other'},
  ];
  static readonly addressLabels = [
    {label: 'Home', value: 'Home'},
    {label: 'Work', value: 'Work'},
    {label: 'Other', value: 'Other'},
  ];
  static readonly eventTypes = [
    {label: 'Birthday', value: 'Birthday'},
    {label: 'Anniversary', value: 'Anniversary'},
    {label: 'Home Purchase', value: 'Home Purchase'},
    {label: 'Home Sale', value: 'Home Sale'},
  ]
}