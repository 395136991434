import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map, share, take, tap } from 'rxjs/operators';

import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'aop-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {
  registrationEmail: string;
  sendEmailDisabled: boolean = false;
  emailSuccess: boolean = false;
  emailError: boolean = false;
  onpointUser: Observable<any>;

  constructor(private authService: AuthenticationService) {
  }

  ngOnInit() {
    this.onpointUser = this.authService.userObservable().pipe(
      // Could confirm user exists, but skipping it since this page
      // is guarded and is not accessible unless there is a user object
      // map(user => {
      //   if (user) {
      //     return user;
      //   } else {
      //     return null;
      //   }
      // }),
      // reload the user to see if the email has been verified
      tap(user => user.reload()),
      // since multiple items on the next page are subscribing, share
      // the observable so there is only one subscription
      share()
    );
  }

  sendEmail() {
    this.sendEmailDisabled = true;
    this.emailSuccess = false;
    this.emailError = false;
    this.authService.sendVerificationEmail().then(() => {
      // console.log('Verification email sent');
      this.emailSuccess = true;
    }).catch(err => {
      this.emailError = true;
      console.error('An error occurred sending verification email');
      console.log(err);
    });
  }

  onAlertClose() {
    this.sendEmailDisabled = false;
  }
}

// Allow user to proceed to dashboard if they want, but make sure it is clear to the use the features will
// be disabled until their email is verified such as saving data, creating contacts and tasks, tracking of
// daily and weekly status.
