import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
// import { delay, map, retryWhen, timeout } from 'rxjs/operators';

// import { AuthenticationService } from '../authentication/authentication.service';
import { OnPointNotification } from '../interfaces/onpoint-notification';

// import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class NotificationsService {
  show = new BehaviorSubject<boolean>(false);
  type = new BehaviorSubject<string>('info');
  message = new BehaviorSubject<string>('Notification service standing by');

  pseudoToast;

  showNotification() {
    return this.show.asObservable();
  }

  notificationType() {
    return this.type.asObservable();
  }

  notificationMessage() {
    return this.message.asObservable();
  }

  enableNotification(notification: OnPointNotification, delay: number = 15000) {
    clearTimeout(this.pseudoToast);
    this.type.next(notification.type);
    this.message.next(notification.message);
    this.show.next(notification.show);
    this.pseudoToast = setTimeout(() => {
      this.show.next(false);
    }, delay);
  }

  disableNotification() {
    clearTimeout(this.pseudoToast);
    this.show.next(false);
  }
}

