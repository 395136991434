<form clrForm [formGroup]="quickAddContactForm" clrLayout="vertical" (ngSubmit)="addContact()" class="quick-add-contact">
  <fieldset [disabled]="serviceBusy">
  <div class="op-form-row">
    <div class="op-form-label clr-col-12 clr-col-md-3">Name</div>
    <clr-input-container class="clr-col-12 clr-col-md-4">
      <input clrInput type="text" formControlName="firstName" placeholder="First" />
      <clr-control-helper>required</clr-control-helper>
      <clr-control-error>Contact's first name is required</clr-control-error>
    </clr-input-container>
    <clr-input-container class="clr-col-12 clr-col-md-5">
      <input clrInput type="text" formControlName="lastName" placeholder="Last" />
      <clr-control-helper>required</clr-control-helper>
      <clr-control-error>Contact's last name is required</clr-control-error>
    </clr-input-container>
  </div>
  <div class="op-form-row">
    <div class="op-form-label clr-col-12 clr-col-md-3">Email</div>
    <clr-input-container class="clr-col-8 clr-col-md-6">
      <input clrInput type="email" formControlName="email" name="email" />
      <clr-control-error *clrIfError="'email'">Please enter a valid email address</clr-control-error>
    </clr-input-container>
    <clr-select-container class="clr-col-4 clr-col-md-3">
      <select clrSelect formControlName="emailLabel">
        <option *ngFor="let label of emailLabels" [ngValue]="label.value">{{ label.label }}</option>
      </select>
    </clr-select-container>
  </div>
  <div class="op-form-row">
    <div class="op-form-label clr-col-12 clr-col-md-3">Phone Number</div>
    <clr-input-container class="clr-col-8 clr-col-md-6">
      <input clrInput type="tel" formControlName="phoneNumber" />
      <clr-control-error *clrIfError="'minlength'">Please enter a valid phone number</clr-control-error>
    </clr-input-container>
    <clr-select-container class="clr-col-4 clr-col-md-3">
      <select clrSelect formControlName="phoneLabel">
        <option *ngFor="let label of phoneLabels" [ngValue]="label.value">{{ label.label }}</option>
      </select>
    </clr-select-container>
  </div>
  <div class="op-form-row">
    <div class="op-form-label clr-col-12 clr-col-md-3">Contact Type</div>
    <clr-select-container class="clr-col-8 clr-col-md-4">
      <select clrSelect formControlName="contactType">
        <option *ngFor="let type of contactTypes" 
                [ngValue]="type.value"
                [selected]="type.value === null"
                [hidden]="type.value === null">{{ type.label }}</option>
      </select>
      <clr-control-helper>required</clr-control-helper>
      <clr-control-error>Please select a value from the dropdown</clr-control-error>
    </clr-select-container>
  </div>
  <div class="op-form-row">
    <clr-textarea-container class="clr-col-12 clr-col-md-10">
      <label>
        Comment
        <clr-signpost>
          <clr-signpost-content [clrPosition]="top-right" *clrIfOpen>
            <p>
              The comment is a static note or description. For instance, you may use it to indicate how you met
              someone, where they are from, or other special pieces of information. For tracking interactions that
              occur over time, use the Notes or Activities features.
            </p>
          </clr-signpost-content>
        </clr-signpost>
      </label>
      <textarea clrTextarea formControlName="comment"></textarea>
    </clr-textarea-container>
  </div>
  </fieldset>
  <button type="submit" class="btn btn-primary" [disabled]="!quickAddContactForm.valid || serviceBusy">Add Contact</button>
  <button type="button" class="btn btn-outline" (click)="clearForm()">Clear Form</button>
  <clr-alert *ngIf="serviceBusy" [clrAlertType]="'warning'" [clrAlertClosable]="false">
    <clr-alert-item>
      <span class="alert-text">Contact service is busy. Please wait.</span>
    </clr-alert-item>
  </clr-alert>
  <clr-alert [(clrAlertClosed)]="!serviceError" [clrAlertType]="'danger'">
    <clr-alert-item>
      <span class="alert-text">{{serviceErrorMessage}}</span>
    </clr-alert-item>
  </clr-alert>
  <clr-alert [(clrAlertClosed)]="!showAlert" [clrAlertType]="alertType">
    <clr-alert-item>
      <span class="alert-text">{{alertMessage}}</span>
    </clr-alert-item>
  </clr-alert>
</form>
