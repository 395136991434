import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';

import { Constants } from '../../../constants';

import { Contact } from '../../interfaces/contact';

@Component({
  selector: 'aop-contact-quick-add',
  templateUrl: './contact-quick-add.component.html',
  styleUrls: ['./contact-quick-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactQuickAddComponent implements OnInit {
  quickAddContactForm: FormGroup;
  firstName: FormControl;
  lastName: FormControl;
  phoneNumber: FormControl;
  phoneLabel: FormControl;
  email: FormControl;
  emailLabel: FormControl;
  contactType: FormControl;
  comment: FormControl;
  showAlert: boolean = false;
  alertType: string = 'info';
  alertMessage: string = 'Contact form ready';

  // contactTypes = ['Buyer', 'Seller', 'Buyer + Seller', 'Prospect', 'Past Client', 'Other'];
  contactTypes = Constants.contactTypes;
  emailLabels = Constants.emailLabels;
  phoneLabels = Constants.phoneLabels;

  // Contact service is not required. Component will emit a validated set of inputs up to the
  // container which will pass to the service for communicating to the server. The container
  // will handle success/error states and pass back to the component that state the form needs
  // to be in - either enabled or disabled. The local logic can only disable the form when the
  // inputs are not valid. Otherwise, the container overrides the state
  // constructor(private contactsService: ContactsTestService) { }
  constructor() { }

  @Input() serviceBusy: boolean;
  @Input() serviceError: boolean;
  @Input() serviceErrorMessage: string;
  @Output() outQuickContact = new EventEmitter<Contact>();

  ngOnInit() {
    this.createFormControls();
    this.createFormGroup();
  }

  createFormControls() {
    this.firstName = new FormControl(null, {
      validators: [
        Validators.required
      ],
      updateOn: 'blur'
    });
    this.lastName = new FormControl(null, {
      validators: [
        Validators.required
      ],
      updateOn: 'blur'
    });
    // TO-DO: Format phone number and add validation check
    this.phoneNumber = new FormControl(null, {
      validators: [
        Validators.minLength(7)
      ],
      updateOn: 'blur'
    });
    this.phoneLabel = new FormControl('Mobile');
    this.email = new FormControl(null, {
      validators: [
        Validators.email
      ],
      updateOn: 'blur'
    });
    this.emailLabel = new FormControl('Home');
    this.contactType = new FormControl(null, {
      validators: [
        Validators.required
      ]
    });
    this.comment = new FormControl();
  }

  createFormGroup() {
    this.quickAddContactForm = new FormGroup({
      firstName: this.firstName,
      lastName: this.lastName,
      phoneNumber: this.phoneNumber,
      phoneLabel: this.phoneLabel,
      email: this.email,
      emailLabel: this.emailLabel,
      contactType: this.contactType,
      comment: this.comment
    });
  }

  // Reseting form from the html side, only need this if reseting in the template
  resetForm() {
    this.quickAddContactForm.reset({
      emailLabel: 'Home',
      phoneLabel: 'Mobile',
      contactType: null,
    });
  }

  addContact() {
    const contactEmail = this.quickAddContactForm.value.email ? 
      [
        {
          email: this.quickAddContactForm.value.email,
          label: this.quickAddContactForm.value.emailLabel,
          isPrimary: true,
        }
      ] :
      [];
    const contactPhone = this.quickAddContactForm.value.phoneNumber ?
      [
        {
          phone: this.quickAddContactForm.value.phoneNumber,
          label: this.quickAddContactForm.value.phoneLabel,
          isPrimary: true,
        }
      ] :
      [];

    const quickCreateContact: Contact = {
      firstName: this.quickAddContactForm.value.firstName,
      lastName: this.quickAddContactForm.value.lastName,
      contactType: this.quickAddContactForm.value.contactType,
      comment: this.quickAddContactForm.value.comment ? this.quickAddContactForm.value.comment : '',
      emails: contactEmail,
      phoneNumbers: contactPhone,
      addresses: [],
      socialProfiles: [],
      occupations: [],
      events: [],
      categories: [],
    }
    if (this.quickAddContactForm.valid) {
      console.log("Form submit action initiated", quickCreateContact);
      this.showAlert = false;
      this.serviceError = false;
      this.outQuickContact.emit(quickCreateContact);
      // For testing error condition
      // this.outQuickContact.emit(this.badApple);
    } else {
      this.showAlert = true;
      this.alertType = 'danger';
      this.alertMessage = 'Validation form error. Please check values and resubmit.';
    }
  }

  clearForm() {
    this.resetForm();
    this.showAlert = false;
    this.serviceError = false;
  }

  // For testing
  badApple: any =  {firstName: 'gregg', lastName: 'borodaty'};
}

/*
NEXT TASKS:
*** Done: Add validators for first name required
Add custom validator for phone number or email
*** Done: Add placeholder describing required fields
Consider signpost for telling user that email or phone number required
*** Done: Emit output/event with client-validated contact object for entering into database
*** Done: In holding container call service and send input message back to component
*** Done: Add message input to component for success/fail
*** Done: Input that accepts loading parameter to tell user that action occurring
  disable button and form
*** Done: When success message comes back, display in an alert for 10 seconds, or allow user
  to dismiss => control the alert state at the container level with another component
*** Done: On success, clear form, On fail, keep form populated
*** Done: When fail message comes back, leave open so user can see error message

TO-DO: define object that the form accepts as an input that has the components necessary to manage state
       The container component managing state will emit the object as a behavior subject/observable that
       the input form can subscribe to.

Quick-add contact is a form that takes in the basic fields to create a contact
This form is accessed using an icon which pops the form up in a modal, or it
can be embedded on any page.
Input verification and sanitation is done at the component level
The form emits an output with the form values to the container it is in that manages the state.
In doing so, the top-level container managing state will call the contact service and
determine next steps on successful contact add or error.
This component receives as input a message variable that tells it whether to display a success
or error alert. Form is disabled until user clears the alert. If Add is successful, then the
form is cleared when the user dismissed the alert. On error message, the form remains filled
in so the user can try again if they like.

Round 2 - use phone number validation library here: https://www.npmjs.com/package/google-libphonenumber
Above was discovered and inspired from example here: https://angular-templates.io/tutorials/about/angular-forms-and-validations
*/