// Using following as template - https://github.com/melcor76/interceptors/blob/master/src/app/interceptors/auth.interceptor.ts
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private AUTH_HEADER = 'Authorization';

  constructor(private authService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // console.warn("Set Authorization Header");

    // TO-DO: If calling an outside domain, use a different interceptor, or return without adding token

    if (!request.headers.has("Content-Type")) {
      request = request.clone({
        headers: request.headers.set("Content-Type", "application/json")
      });
    }

    // TO-DO: Add catchError and decide which generic errors will be handled in the interceptor, and
    // the remaining errors will be sent downstream for handling.
    return this.authService.idTokenObservable().pipe(
      take(1),
      switchMap(token => {
        if (token) {
          request = request.clone({
            headers: request.headers.set("Authorization", `Bearer ${token}`)
          });
        }
        return next.handle(request);
      })
      // catchError operator will go here to catch any error responses
      // Need to check error messages from Firebase for following conditions:
      // 1. Invalid token: Redirect user to login with generic error message
      // 2. Expired token: Redirect user to login with session expired message, return to requested page
      // 3. No token present: Return user to login with please login to continue, return to requested page on success
    );
  }
}
