import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { DailyThought } from 'src/app/interfaces/daily-thought';

@Component({
  selector: 'aop-daily-thought',
  templateUrl: './daily-thought.component.html',
  styleUrls: ['./daily-thought.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DailyThoughtComponent {
  @Input() dailyThought: DailyThought;

  constructor() { }

}
