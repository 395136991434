import { Pipe, PipeTransform } from '@angular/core';

// Formats the phone number based on the country code into
// a properly displayed number. If no country code is specified
// US is assumed.

@Pipe({
  name: 'formatPhone'
})
export class FormatPhonePipe implements PipeTransform {

  transform(value: string): string {
    // Check for null phone value first
    if (value && !value.match(/\d/)) return value;
    let formattedPhone: string;
    const trimmedPhone = value ? value.trim() : '';
    if (trimmedPhone.length === 10) {
      const areaCode = trimmedPhone.slice(0,3);
      const exchange = trimmedPhone.slice(3,6);
      const number = trimmedPhone.slice(6);
      formattedPhone = `(${areaCode}) ${exchange}-${number}`;
    } else if (trimmedPhone.length === 7) {
      const exchange = trimmedPhone.slice(0,3);
      const number = trimmedPhone.slice(3);
      formattedPhone = `${exchange}-${number}`;
    } else {
      formattedPhone = trimmedPhone;
    }
    return formattedPhone;
  }

}

/*
Future upgrade is to use this library/fucntion to convert the phone number
https://www.npmjs.com/package/libphonenumber-js
*/
