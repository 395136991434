import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// *** TO-DO: Remove InMemoryWebApiModule if no longer use 'fake' data
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ClarityModule } from '@clr/angular';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { RetryInterceptor } from './interceptors/retry.interceptor';

import { environment } from '../environments/environment';
import { Database } from './database'; // Used for in-memory-web-api testing purposes

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    environment.production ? [] : HttpClientInMemoryWebApiModule.forRoot(Database, {
      passThruUnknownUrl: true
    }),
    BrowserAnimationsModule,
    ClarityModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    // Authentication module needs loaded in the appModule because it is not a feature
    // module that exists inside the application shell
    AuthenticationModule,
    // Modules are being lazy loaded, using loadChildren in the appRouting module.
    // Therefore, they do not need to loaded here.
    // DashboardModule,
    // ContactsModule,
    AppRoutingModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
