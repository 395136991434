<clr-main-container>
  <aop-app-alerts [emailVerified]="userEmailVerified | async"></aop-app-alerts>
  <aop-navigation></aop-navigation>
    <div class="notification-toast" [ngStyle]="{'opacity': (showNotification | async) ? 1 : 0}">
      <div>
        <clr-alert [clrAlertClosed]="!modalState" 
                   (clrAlertClosedChange)="hideNotificationChange($event)"
                   [clrAlertType]="notificationType | async">
                   <!-- [ngClass]="{'aop-alert-spacer': hideNotification | async}"> -->
          <clr-alert-item>
            <span class="alert-text">
              {{ notificationMessage | async }}
            </span>
          </clr-alert-item>
        </clr-alert>
      </div>
    </div>
  <div class="content-container">
    <main class="content-area">
      <!-- <div class="clr-row">
        <div class="clr-col-12 clr-col-lg-6 clr-col-md-8"> -->
        <!-- </div>
      </div> -->
      <router-outlet></router-outlet>
    </main>
    <nav class="sidenav" [clr-nav-level]="2">
      <router-outlet name="secondary"></router-outlet>
    </nav>
  </div>
</clr-main-container>
