// Using following as template - https://github.com/melcor76/interceptors/blob/master/src/app/interceptors/auth.interceptor.ts
// May want to review #5 here - https://medium.com/angular-in-depth/top-10-ways-to-use-interceptors-in-angular-db450f8a62d6
// Alternative way to handle the errors that may be simplified
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, filter, map, retryWhen, switchMap, take, timeout } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // console.warn("Set Authorization Header");

    // TO-DO: If calling an outside domain, return without retrying service (for now)

    // TO-DO: Add catchError and decide which generic errors will be handled in the interceptor, and
    // the remaining errors will be sent downstream for handling.
    return next.handle(request).pipe(
      // Setup timeout in case of slow response or network problem. Allow user to recover manually.
      // Setting timeout higher than Gateway timeout to allow for retry in case of cold start
      // on the server.
      // Should add handler for TimeoutError in each service, or create a utility function for
      // handling errors and provide user a means to retry the server
      timeout(60000),
      // if a 5xx server error occurs, it may be transient, such as a cold start error. Retry 5xx
      // errors twice, then throw the error. For other response codes, throw error immediately
      retryWhen(err => {
        let retries = 0;
        return err.pipe(
          // Wait one second between retries
          delay(1000),
          map (error => {
            if (retries++ < 2 && (error.status >=500 || error.name === 'TimeoutError')) {
              console.log('retrying OnPoint server', retries);
              return error;
            } else {
              throw error;
            }
          })
        )
      })
    )
  }
}
