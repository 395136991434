import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'aop-sidenav-quick-actions',
  templateUrl: './sidenav-quick-actions.component.html',
  styleUrls: ['./sidenav-quick-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavQuickActionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
