<clr-alerts>
  <clr-alert *ngIf="(emailVerified !== null) && (!emailVerified)" [clrAlertType]="'warning'" clrAlertAppLevel="true" [clrAlertClosable]="false">
    <clr-alert-item>
      <span class="alert-text">
        Please verify your email to unlock all OnPoint features
      </span>
      <div class="alert-actions">
        <button class="btn alert-action" [routerLink]="['/verification']">Verify</button>
      </div>
    </clr-alert-item>
  </clr-alert>
</clr-alerts>
