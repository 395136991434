import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClarityModule } from '@clr/angular';

import { AppAlertsComponent } from './app-alerts/app-alerts.component';
import { ContactQuickAddComponent } from './contact-quick-add/contact-quick-add.component';
import { ContactSearchFilterComponent } from './contact-search-filter/contact-search-filter.component';
import { ContactsTableComponent } from './contacts-table/contacts-table.component';
import { DailyThoughtComponent } from './daily-thought/daily-thought.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SidenavQuickActionsComponent } from './sidenav-quick-actions/sidenav-quick-actions.component';
import { OnPointContainerComponent } from './on-point-container/on-point-container.component';

import { FormatPhonePipe } from './pipes/format-phone.pipe';
import { FormatAddressPipe } from './pipes/format-address.pipe';

@NgModule({
  declarations: [
    ContactSearchFilterComponent,
    ContactQuickAddComponent,
    ContactsTableComponent,
    DailyThoughtComponent,
    NavigationComponent,
    OnPointContainerComponent,
    SidenavQuickActionsComponent,
    AppAlertsComponent,
    FormatPhonePipe,
    FormatAddressPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ClarityModule
  ],
  exports: [
    AppAlertsComponent,
    ContactSearchFilterComponent,
    ContactQuickAddComponent,
    ContactsTableComponent,
    DailyThoughtComponent,
    NavigationComponent,
    SidenavQuickActionsComponent,
    FormatPhonePipe,
    FormatAddressPipe
  ]
})
export class SharedModule { }
