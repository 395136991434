import { InMemoryDbService } from 'angular-in-memory-web-api';
import * as faker from 'faker/locale/en_US';

import { DailyThought } from './interfaces/daily-thought';

export class Database implements InMemoryDbService {
  createDb() {
    const contacts: any[] = this.generateContacts();
    const dailyThought: DailyThought[] = this.getDailyQuote();
    return {contacts, dailyThought};
  }

  generateContacts(): any[] {
    let fakeContacts: any[] = [];
    let contactTypes: string[] = ['Buyer', 'Seller', 'Buyer + Seller', 'Prospect', 'Past Client', 'Other'];
    // Setting seed will generate consistent results every time contacts are loaded
    faker.seed(28);
    for (let i = 0; i < 56; i++) {
      fakeContacts.push({
        id: faker.random.uuid(),
        firstName: faker.name.firstName(),
        lastName: faker.name.lastName(),
        phoneNumber: faker.phone.phoneNumberFormat(1),
        lastModified: faker.date.recent(180),
        contactType: faker.random.arrayElement(contactTypes),
        ytdPoints: faker.random.number(200)
      });
    }
    for (let i = 0; i < fakeContacts.length; i++) {
      let randomEmail = faker.internet.exampleEmail(fakeContacts[i]["firstName"]);
      fakeContacts[i]["email"] = randomEmail;
    }
    // console.table(fakeContacts);
    return fakeContacts;
  }
// More quote here - https://www.briantracy.com/blog/personal-success/26-motivational-quotes-for-success/
  getDailyQuote(): DailyThought[] {
    let currentDate = new Date();
    let dailyThought: DailyThought[] = [];
    let thoughtsOfTheDay: DailyThought[] = [
      { id: faker.random.uuid(), displayDate: '3/27/2019', quote: "80/20 Rule: 20% of what I do determines 80% of what I accomplish. Focus on the 'Vital Few' (the 20%).", author: "Pareto's Principle" },
      { id: faker.random.uuid(), displayDate: '3/28/2019', quote: "Your passion must exceed your fear.", author: "Ninja Selling" },
      { id: faker.random.uuid(), displayDate: '3/29/2019', quote: "Setting goals in a function of the conscious mind. Reaching goals is a function of the nonconscious mind.", author: "Jake Shoptaugh" },
      { id: faker.random.uuid(), displayDate: '3/30/2019', quote: "Your true worth is determined by how much more you give in value than you receive in payment.", author: "Bob Burg and John David Mann, The Go-Giver" },
      { id: faker.random.uuid(), displayDate: '3/31/2019', quote: "We become what we repeatedly do. Excellence, then is not an act, but a habit.", author: "Aristotle" },
      { id: faker.random.uuid(), displayDate: '4/1/2019', quote: "Only I can change my life. No one else can do it for me.", author: "Carol Burnett" },
      { id: faker.random.uuid(), displayDate: '4/2/2019', quote: "Your nonconscious brain is a recording system of your entire life - all experiences and all thoughts - which become your 'program'.", author: "Lou Tice" },
      { id: faker.random.uuid(), displayDate: '4/3/2019', quote: "You can't build a reputation on what you are going to do.", author: "Henry Ford" },
      { id: faker.random.uuid(), displayDate: '4/4/2019', quote: "It does not take sharp eyes to see the sun and the moon, nor does it take sharp ears to hear the thunderclap. Wisdom is not obvious. You must see the subtle and notice the hidden to be victorious.", author: "Sun Tzu" },
      { id: faker.random.uuid(), displayDate: '4/5/2019', quote: "Your next transaction is embedded in this transaction.", author: "Larry Kendall" },
      { id: faker.random.uuid(), displayDate: '4/6/2019', quote: "I've learned that people will forget what you said, people will forget what you did, but people will never forget how you made them feel.", author: "Maya Angelou" },
      { id: faker.random.uuid(), displayDate: '4/7/2019', quote: "Many of life's failures are people who did not realize how close they were to success when they gave up.", author: "Thomas Edison" },
      { id: faker.random.uuid(), displayDate: '4/8/2019', quote: "Most people know 10 Realtors. They pick their Realtor based on FLOW.", author: "NINJA SELLING" },
      { id: faker.random.uuid(), displayDate: '4/9/2019', quote: "You must expect great things of yourself before you can do them.", author: "Michael Jordan" },
      { id: faker.random.uuid(), displayDate: '4/10/2019', quote: "My mind is programmed by what I see, hear, feel, think, and the people I hang out with.", author: "NINJA SELLING" },
      { id: faker.random.uuid(), displayDate: '4/11/2019', quote: "Worry is a prayer for what you don't want", author: "John Assaraf" },
      { id: faker.random.uuid(), displayDate: '4/12/2019', quote: "We become what we think about", author: "James Allen" },
      { id: faker.random.uuid(), displayDate: '4/13/2019', quote: "Stop actually thinking about winning and losing and instead focus on those daily activities that cause success", author: "Nick Saban" },
      { id: faker.random.uuid(), displayDate: '4/14/2019', quote: "Clarify the problem before presenting a solution", author: "Steve Jobs" },
      { id: faker.random.uuid(), displayDate: '4/15/2019', quote: "Your life becomes a printout resulting from the small decisions you make each day that are compounded over time.", author: "Jeff Olson" },
      { id: faker.random.uuid(), displayDate: '4/16/2019', quote: "Optimism is the faith that leads to achievement. Nothing can be done without hope and confidence.", author: "Hellen Keller" },
      { id: faker.random.uuid(), displayDate: '4/17/2019', quote: "Life is 10% what happens to you and 90% how you react to it.", author: "Charles R. Swindoll" },
      { id: faker.random.uuid(), displayDate: '4/18/2019', quote: "The way to get started is to quit talking and begin doing.", author: "Walt Disney" },
      { id: faker.random.uuid(), displayDate: '4/19/2019', quote: "The pessimist sees difficulty in every opportunity. The optimist see opportunity in every difficulty.", author: "Winston Churchill" }
    ];
    let currentThought: DailyThought = thoughtsOfTheDay.find(thought => {
      return thought.displayDate === currentDate.toLocaleDateString();
    });
    if (currentThought !== undefined ) {
      dailyThought.push(currentThought);
    } else {
     dailyThought.push(thoughtsOfTheDay[0]);
    }
    return dailyThought;
  }
}

// Using static data
  // createDb() {
  //   const contacts: any[] = [
  //     {id: 1, firstName: 'Jerry', lastName: 'Seinfeld'},
  //     {id: 2, firstName: 'George', lastName: 'Costanza'},
  //     {id: 3, firstName: 'Elaine', lastName: 'Benes'},
  //     {id: 4, firstName: 'Cosmo', lastname: 'Kramer'}
  //   ];
  //   return {contacts};
  // }
  
  // Using IIFE arrow function
  // createDb() {
  //   const contacts: any[] = (() => {
  //     let fakeContacts: any[] = [];
  //     for (let i = 0; i < 20; i++) {
  //       fakeContacts.push({
  //         id: faker.random.uuid(),
  //         firstName: faker.name.firstName(),
  //         lastName: faker.name.lastName()
  //       });
  //     }
  //     console.table(fakeContacts);
  //     return fakeContacts;
  //   })();
  //   return {contacts};
  // }