<clr-datagrid [clrDgLoading]=loading>
    <clr-dg-column [clrDgSortBy]="'firstName'">First Name</clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'lastName'">Last Name</clr-dg-column>
    <clr-dg-column>Email</clr-dg-column>
    <clr-dg-column>Phone Number</clr-dg-column>
    <clr-dg-column>Type</clr-dg-column>
    <!-- <clr-dg-column [clrDgSortBy]="'ytdPoints'">YTD Points</clr-dg-column> -->
    <clr-dg-column [clrDgSortBy]="'updated'">Last Updated</clr-dg-column>
    
  <clr-dg-placeholder>No contacts found that match seclected criteria.</clr-dg-placeholder>

    <clr-dg-row (click)="contactAction(contact, 'view')" *clrDgItems="let contact of contactsList">
      <clr-dg-cell style="cursor: pointer"  (click)="contactAction(contact, 'view')">{{contact.firstName}}</clr-dg-cell>
      <clr-dg-cell style="cursor: pointer"  (click)="contactAction(contact, 'view')">{{contact.lastName}}</clr-dg-cell>
      <clr-dg-cell *ngIf="status === 'active'; else altEmailCell"><a *ngIf="contact.email" href="mailto:{{contact.email}}" (click)="tableLinkHandler($event)">{{contact.email}}</a></clr-dg-cell>
      <ng-template #altEmailCell><clr-dg-cell>{{contact.email}}</clr-dg-cell></ng-template>
      <clr-dg-cell *ngIf="status === 'active'; else altPhoneCell"><a *ngIf="contact.phone" href="tel:+1{{contact.phone.phone}}" (click)="tableLinkHandler($event)"><span style="white-space:nowrap">{{contact.phone | formatPhone}}</span></a></clr-dg-cell>
      <ng-template #altPhoneCell><clr-dg-cell><span style="white-space:nowrap">{{contact.phone | formatPhone}}</span></clr-dg-cell></ng-template>
      <clr-dg-cell style="cursor: pointer">{{contact.contactType}}</clr-dg-cell>
      <!-- <clr-dg-cell>{{contact.ytdPoints}}</clr-dg-cell> -->
      <clr-dg-cell style="cursor: pointer">{{contact.updated | date:'MM/dd/yyyy'}}</clr-dg-cell>
      <clr-dg-action-overflow (click)="tableLinkHandler($event)">
        <button clrPopoverCloseButton class="action-item" (click)="contactAction(contact, 'view')">View</button>
        <button *ngIf="status !== 'deleted'" clrPopoverCloseButton class="action-item" (click)="contactAction(contact, 'edit')">Edit</button>
        <button *ngIf="status !== 'active'" clrPopoverCloseButton class="action-item" (click)="contactAction(contact, 'activate')">Activate</button>
        <button *ngIf="status !== 'archived'" clrPopoverCloseButton class="action-item" (click)="contactAction(contact, 'archive')">Archive</button>
        <button *ngIf="status !== 'deleted'" clrPopoverCloseButton class="action-item" (click)="contactAction(contact, 'delete')">Delete</button>
        <button *ngIf="status === 'deleted'" clrPopoverCloseButton class="action-item" (click)="deleteContact(contact)">Delete Permanently</button>
      </clr-dg-action-overflow>
    </clr-dg-row>
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="20">
        <!-- <clr-dg-page-size [clrPageSizeOptions]="[10,20,50]">Contacts per page </clr-dg-page-size> -->
        {{contactsList?.length}} contacts
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
  
  
  

  <clr-alert *ngIf="status === 'deleted'" 
             [clrAlertClosable]=false 
             [clrAlertType]="'warning'" 
             style="margin-top: 15px;">
             <clr-alert-item>
               <span class="alert-text">
                Contacts that have been in Trash for more than 90 days will be automatically deleted
               </span>
             </clr-alert-item> 
  </clr-alert>

  <clr-modal [clrModalOpen]="modalActive" (clrModalOpenChange)="modalChange($event)" [clrModalStaticBackdrop]="false">
    <h3 class="modal-title">Delete Contact</h3>
    <div class="modal-body">
      <p>Are you sure you want to permanently delete the selected contact? Select 'OK' to confirm.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="cancelDelete()">
        Cancel
      </button>
      <button type="button" class="btn btn-primary" (click)="confirmDelete()">
        OK
      </button>
    </div>
  </clr-modal>

<!-- <div>
  <h4>Table features to add:</h4>
  <ul>
    <li>Start with table filtered by last name</li>
    <li>Add sort dropdown at top of page to sort by first, last, type, updated, with asc/desc selector next to it</li>
    <li>Add clear sort button</li>
    <li>Add signpost to let users know what sort columns are available (or help at bottom of table)</li>
    <li>Filter type</li>
    <li>Click on email/phone to email/call? If so, then need to limit view to name</li>
    <li>Add button to view Archived contacts (Showing: Active / Archived)</li>
    <li>From this table, just use single selection. Make another area for batch actions, such as delete, archive, import, export</li>
  </ul>
</div> -->
