import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OnPointContainerComponent } from './shared/on-point-container/on-point-container.component';

import { AuthGuard } from './authentication/guards/auth.guard';

const routes: Routes = [
  { 
    path: '', 
    component: OnPointContainerComponent,
    canActivate: [AuthGuard],
    children: [
      // Lazy loading routing modules using the techniques described here:
      // https://angular.io/guide/lazy-loading-ngmodules
      // https://medium.com/angular-in-depth/angular-router-concepts-patterns-62a29707e93f
      { 
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      { 
        path: 'contacts',
        loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule)
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ]
  },
  { path: '**', redirectTo: '/dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
