<form class="clr-form clr-form-horizontal">
  <div>
    <div class="clr-form-control search-term">
      <div class="clr-input-wrapper">
          <clr-icon shape="search" size="20"></clr-icon>
          <input type="search" class="clr-input" placeholder="Search Contacts by Name" [formControl]="searchFilterName" />
      </div>
    </div>
    <!-- <clr-input-container class="clr-form-control">
        <label><clr-icon shape="search" size="20"></clr-icon></label>
        <input type="search" clrInput placeholder="Search Contacts by Name" [formControl]="searchFilterInput" />
    </clr-input-container> -->
    <div class="clr-form-control">
      <label for="status">Showing:</label>
      <div class="clr-select-wrapper">
        <select name="status" class="clr-select" [formControl]="searchFilterStatus">
          <option value="active" selected>Active</option>
          <option value="archived">Archived</option>
          <option value="deleted">Trash</option>
        </select>
      </div>
    </div>
  </div>
</form>

