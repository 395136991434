import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthenticationService } from '../authentication.service';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'aop-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registrationForm: FormGroup;
  email: FormControl;
  password: FormControl;
  confirmPassword: FormControl;
  registrationCode: FormControl;
  registrationErrorTitle: string = null;
  registrationErrorMessage: string = null;
  registering: boolean = false;

  registrationCodes: string[] = environment.registrationCodes;
  // registrationCodes: string[] = ['onpoint-preview', 'aumnia'];

  constructor(private router: Router,
    private authService: AuthenticationService) { }

  ngOnInit() {
    this.createFormControls();
    this.createFormGroup();
    // console.log('valid codes: ', this.registrationCodes);
  }

  createFormControls() {
    this.email = new FormControl('', {
      validators: [
        Validators.required,
        Validators.email
      ],
      updateOn: 'blur'
    });
    this.password = new FormControl('', {
      validators: [
        Validators.required,
        Validators.minLength(8)
      ],
      updateOn: 'blur'
    });
    this.confirmPassword = new FormControl('', {
      validators: [
        Validators.required
      ]
    });
    this.registrationCode = new FormControl('', {
      validators: [
        this.checkRegistrationCode
      ],
      // updateOn: 'blur'
    });
  }

  createFormGroup() {
    this.registrationForm = new FormGroup({
      email: this.email,
      password: this.password,
      confirmPassword: this.confirmPassword,
      registrationCode: this.registrationCode,
    }, {
      validators: this.confirmPasswordValidator
    });
  }

  formInputState(state: string) {
    this.registrationForm.controls['email'][state]();
    this.registrationForm.controls['password'][state]();
    this.registrationForm.controls['confirmPassword'][state]();
    this.registrationForm.controls['registrationCode'][state]();
  }

  resetForm(arg: string) {
    switch(arg) {
      case "all": {
        this.registrationForm.reset();
        break;
      }
      case "email": {
        this.email.reset();
        break;
      }
      case "password": {
        this.password.reset();
        this.confirmPassword.reset();
        break;
      }
      default: {
        this.registrationForm.reset();
        console.error('Unrecognized argument, ' + arg + ', fed to function; resetting entire form.');
      }
    }
  }

  resetRegistrationErrors() {
    this.registrationErrorTitle = null;
    this.registrationErrorMessage = null;
  }

  // Custom validator used by the createFormGroup method to check that the password
  // matches the confirm password field.
  // Values checked one character at a time
  confirmPasswordValidator = function (control: FormGroup): ValidationErrors | null {
    let passwordMatch: Object = null;
    const password: string = control.get('password').value ? control.get('password').value : '';
    const confirmPassword: string = control.get('confirmPassword').value;
    const numChars: number = confirmPassword ? confirmPassword.length : 0;
    if (confirmPassword === password.substr(0, numChars)) {
      if (confirmPassword === password) {
        passwordMatch = null;
      } else {
        passwordMatch = {
          'unconfirmed': true
        };
      }
    } else {
      passwordMatch = {
        'mismatch': true,
        'unconfirmed': true
      };
    }
    // Only run the check if both fields have a value in them
    // return !password || !confirmPassword || passwordMatch ? null : { 'mismatch': true };
    return !password || !confirmPassword ? null : passwordMatch;
  }

  // While in preview mode, users need to use a registration code to pass validation
  checkRegistrationCode(control: FormControl): ValidationErrors | null {
    const registrationCode: string = control.value ? control.value : '';
    const registrationCodes = environment.registrationCodes
    const registrationCodeCheck = registrationCodes.includes(registrationCode);
    return registrationCodeCheck ? null : { 'invalidCode': true};
  }

  registerUser() {
    // Set variable in function to track successful registration through promise chain
    // On success, user is redirected to welcome page; otherwise, user is kept on registration
    // Initialize to false - must be explicitly set to true on success
    let userCreated: boolean = false;
    
    console.log('Submitting registration form');
    this.resetRegistrationErrors();
    // Defensive programming just to make sure that registrationForm validated
    if (this.registrationForm.valid) {

      // Disable input fields, next button, clear previous error messages, enable spinner
      this.formInputState('disable');
      this.registering = true;

      // Put form values into variables in case needed for error messages
      let email: string = this.registrationForm.value.email;
      let password: string = this.registrationForm.value.password;

      // Use authService function to createUser
      this.authService.createUser(email, password).then(res => {
        // On success, set userCreated to true
        userCreated = true;

        // DEBUG: Print successful registration result to console for review
        console.log(res);

        // Return promise to send verification email to user
        return this.authService.sendVerificationEmail();
      }).then(() => {
        // DEBUG: Confirm verification email sent
        // console.log('User verification email sent.');

        // User registered, email successfully sent, put user on welcome page
        this.router.navigate(['/welcome']);
      }).catch(err => {
        console.log(err);
        this.registrationErrorTitle = "Account could not be not created";
        switch(err.code) {
          case "auth/invalid-email": {
            this.registrationErrorMessage = "A valid email address is required to create your account. Please verify your email and try again.";
            this.resetForm('all');
            break;
          }
          case "auth/weak-password": {
            this.registrationErrorMessage = "Password must be at least 8 characters long. Please select another password and try again.";
            this.resetForm('password');
            break;
          }
          case "auth/email-already-in-use": {
            this.registrationErrorMessage = "The email, " + email + ", has already been registered. Please return to the login page to sign in. If you have forgotten your password, you can use the 'Forgot Password' link on the bottom of the page to reset your password."
            this.resetForm('password');
            break;
          }
          default: {
            if (userCreated) {
              // Error occurred sending verification email. Report error to console, but go ahead
              // and send user to welcome page since there isn't any action the user can take.
              console.log('An error occured sending verification email: ' + err.code);
              this.router.navigate(['/welcome']);
            } else {
              this.registrationErrorMessage = "An unexpected error occurred during registration. Please verify form fields and values, and try again.";
            }
            break;
          }
        }
      }).finally(() => {
        // Re-enable form, disable spinner
        this.formInputState('enable');
        this.registering = false;
      });
    } else {
      // Unexpected error occurred, and form was submitted before validation, set error messages.
      this.registrationErrorTitle = "Account could not be created";
      this.registrationErrorMessage = "Registration is not valid. Please re-verify form fields and values, and try again.";
    }
    // DEBUG - check form values
    // console.table(this.registrationForm.value);
  }
}
 /*
TO-DO:
1. Route user to next location in app when registration completes, which will be the dashboard 
An alert will display at top of navigation reminding user to validate their email
2. Catch errors from confirm email verification
3. Check how to redirect to application via verification email
4. Advanced: Send a Welcome email to the user when they complete their registration using
Google Cloud functions - https://firebase.google.com/docs/functions/use-cases#other_interesting_notification_use_cases,
or just use the existing AWS back-end to send an email when the user is successfully created in the
user database

X. On interstitial page, provide option to resend verification email <-- Not necessary, going to let user use app before email verification

 */