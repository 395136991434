import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { ContactTableFilter } from '../../interfaces/contact-table-filter';
import { ContactsTableComponent } from '../contacts-table/contacts-table.component';

@Component({
  selector: 'aop-contact-search-filter',
  templateUrl: './contact-search-filter.component.html',
  styleUrls: ['./contact-search-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactSearchFilterComponent implements OnDestroy, OnInit {
  @Input() filterStatus: string;
  @Output() searchFilterTerm = new EventEmitter<ContactTableFilter>();
  searchFilterName: FormControl;
  searchFilterStatus: FormControl;

  searchNameFilter$: Subscription;
  searchStatusFilter$: Subscription;

  constructor() {
  }

  // Once component is initialized, create bindings and setup the subscription
  // to the form control valueChanges Observable in order to detect the changes
  // in the search/filter input element. Within the Subscription, the search/filter
  // term will be emitted on the output of the component for handling by the 
  // parent container.
  ngOnInit() {
    this.searchFilterName = new FormControl('');
    // Set the default value in the status filter by the incoming input, or active, if none specified
    this.searchFilterStatus = new FormControl(this.filterStatus ? this.filterStatus: 'active');
    // Emit new value when name filter changes
    this.searchNameFilter$ = this.searchFilterName.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((searchTerm: string) => this.searchFilterTerm.emit({
      searchTerm,
      status: this.searchFilterStatus.value,
    }));
    // Emit new value when status filter changes
    this.searchStatusFilter$ = this.searchFilterStatus.valueChanges.pipe(
      distinctUntilChanged()
    ).subscribe((status: string) => this.searchFilterTerm.emit({
      searchTerm: this.searchFilterName.value,
      status,
    }));
  }

  // Unload the subscription if/when the search/filter input is removed
  ngOnDestroy() {
    this.searchNameFilter$.unsubscribe();
    this.searchStatusFilter$.unsubscribe();
  }
}

// For consideration in the future
// Use <ng-content> in the html to allow the user to define the input parameters such
// as placeholder text to provide more flexibility to the search filter component so
// it can be used for any area where a typeahead style component is desired.
