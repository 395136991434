import { Pipe, PipeTransform } from '@angular/core';

import { Address } from '../../interfaces/contact';

@Pipe({
  name: 'formatAddress'
})
export class FormatAddressPipe implements PipeTransform {

  transform(value: Address): string {
    let address: string = '';

    // First check the streetAddress1 and streetAddress2
    if (value.streetAddress1.trim().length > 0 && value.streetAddress2.trim().length > 0) {
      // if both are greater than 0, then put both into the string
      // otherwise, one or both are empty, so proceed to next if to see if one has a value
      address += `${value.streetAddress1.trim()}\n${value.streetAddress2.trim()}`;
    } else if (value.streetAddress1.trim().length > 0) {
      // don't need to check both, otherwise first test would have passed
      address += `${value.streetAddress1.trim()}`;
    } else if (value.streetAddress2.trim().length > 0) {
      // don't need to check both, otherwise first test would have passed
      address += `(empty/missing address line)\n${value.streetAddress2.trim()}`;
    }

    // Check city, state, zip
    // Not worried about zip - if it is empty string, will just tack onto end of string
    if (value.city.trim().length > 0 && value.state.trim().length > 0) {
      // check value of address to see if line return needs added
      address += address.length > 0 ? `\n` : ``;
      address += `${value.city.trim()}, ${value.state.trim()} ${value.postalCode.trim()}`;
    } else if (value.city.trim().length > 0) {
      // don't need to check both, other first test would have passed
      // check value of address to see if line return needs added
      address += address.length > 0 ? `\n` : ``;
      address += `${value.city.trim()} ${value.postalCode.trim()}`;
    } else if (value.state.trim().length > 0) {
      // don't need to check both, other first test would have passed
      // check value of address to see if line return needs added
      address += address.length > 0 ? `\n` : ``;
      address += `${value.state.trim()} ${value.postalCode.trim()}`;
    } else if (value.postalCode.trim().length > 0) {
      // check postal code, just in case
      address += address.length > 0 ? `\n` : ``;
      address += `${value.postalCode.trim()}`;
    }

    return address;
  }

}

/*
Future improvement would be adding arguments that allow for sending
back an address that is just modified by city, state, zip; or one
that adds the country on a separate line; or formatting address according
to the country since all postal addresses can be different
*/
