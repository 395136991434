<div class="login-wrapper">
  <form [formGroup]="signinForm" (ngSubmit)="authenticateUser()" class="login" novalidate>
    <section class="title">
      <h3 class="welcome">Welcome to</h3>
      Agent OnPoint
      <p *ngIf="displayLogoutMessage" class="logout-message">
        You have been successfully logged out.
      </p>
      <h5>Please login to continue</h5>
    </section>
    <div class="login-group">
      <clr-input-container>
        <label for="emailAddress">Email Address</label>
        <input type="text" formControlName="email" name="emailAddress" clrInput />
        <clr-control-error>Please enter a valid email address to continue</clr-control-error>
      </clr-input-container>
      <clr-password-container>
        <label for="password">Password</label>
        <input type="password" formControlName="password" name="password" clrPassword />
        <clr-control-error *clrIfError="'required'">Please enter your password to continue</clr-control-error>
      </clr-password-container>
      <div class="remember-me-wrapper">
        <clr-checkbox-wrapper>
          <label>Remember me</label>
          <input type="checkbox" formControlName="rememberMe" name="rememberMe" clrCheckbox />
        </clr-checkbox-wrapper>
        <clr-signpost>
          <a class="signpost-action btn btn-small btn-link" clrSignpostTrigger>
            <clr-icon shape="help"></clr-icon>
          </a>
          <clr-signpost-content [clrPosition]="right-middle" *clrIfOpen>
            <p>Selecting 'Remember Me' will keep you logged into your Agent OnPoint account on this device until you logout.
            <span class="text-danger font-weight-bold">Do not select this option</span> when using shared or public computers, in which case be sure to exit
            Agent OnPoint by logging out or closing the browser when you finish your session.</p>
          </clr-signpost-content>
        </clr-signpost>
      </div>
      <div *ngIf="signinErrorTitle">
        <p class="error active">
          {{ signinErrorTitle }}
        </p>
        <p *ngIf="signinErrorMessage" class="signin-error-message">
          {{ signinErrorMessage }}
        </p>
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="!signinForm.valid">
        <span *ngIf="!authenticating">Login</span>
        <span *ngIf="authenticating" class="spinner spinner-inline"></span><span *ngIf="authenticating" style="text-transform: none;">&nbsp;Authenticating</span>
      </button>
      <p class="text-center">
        New to Agent OnPoint?<br />
        <a routerLink="/register">Create your account</a>
      </p>
    </div>
  </form>
</div>