<clr-main-container>
  <aop-app-alerts></aop-app-alerts>
  <aop-navigation></aop-navigation>
  <div class="content-container">
    <main class="content-area">
      <div class="wrapper">
        <p>
          and thank you for creating your account! We're excited to have you on board. Before we can let you
          get to the good stuff, there are a few housekeeping items we need to take care of:
        </p>
        <ul class="op-welcome-list">
          <li>
            A verification email has been sent to the email address, <b>{{ (onpointUser | async)?.email }}</b>, which you used to
            create your account. You can start to explore OnPoint before you verify your email address, but you won't
            have access to key features such as importing and adding contacts, setting goals, and tracking activities.
          </li>
          <li>
            If you are unable to locate your verification email, check your promotions, updates, or spam folders.
            Unfortunately, we don't control the email clients, and they more often than not tend to have a mind of their
            own.
          </li>
          <li>
            If you accidentally delete or can't find the email, you can resend it using the link at the
            bottom of this page.
          </li>
          <li>
            Once you've successfully verified your email, you will need to log out of OnPoint for the verification to
            take effect. The next time you log in to OnPoint, you will see all of the cool features that you really want
            to use will be unlocked.
          </li>
          <li>
            If you are starting OnPoint using a free trial, your trial period will not start until your email has been
            verified.
          </li>
        </ul>
        <p>
          If you do not receive the email, or you are getting errors when you attempt to resend it, please reach out to
          us at support@aumnia.com, and we will look into what may be happening underneath the covers.
        </p>
        <p style="margin-bottom: 1rem;">
          Ready to go? We are. Please select one of the actions below to get started:
        </p>
        <hr />
        <p>
          I want to explore and will verify my email later, take me into OnPoint.<br />
          <button class="btn btn-link" [routerLink]="['/dashboard']">Take me to OnPoint</button>
        </p>
        <hr />
        <p>
          I've successfully verified my email and am ready to start using OnPoint.<br />
          I want to logout and log back in to start using all of OnPoint's features.<br />
          <button class="btn btn-link" (click)="logout()">Log me out</button>
        </p>
        <hr />
        <p>
          The internet gnomes have done something with my verification email, please send me another one.<br />
          <button class="btn btn-link" [disabled]="sendEmailDisabled" (click)="sendEmail()">Resend verification
            email</button>
        </p>
        <clr-alert *ngIf="emailSuccess" [clrAlertType]="'success'" (clrAlertClosedChange)="onAlertClose()">
          <clr-alert-item>
            <span class="alert-text">
              Verification email successfully sent.
            </span>
          </clr-alert-item>
        </clr-alert>
        <clr-alert *ngIf="emailError" [clrAlertType]="'danger'" (clrAlertClosedChange)="onAlertClose()">
          <clr-alert-item>
            <span class="alert-text">
              An error occurred sending the verification, please close the alert and try again.
              If the problem persists, please contact support for assistance.
            </span>
          </clr-alert-item>
        </clr-alert>
      </div>
    </main>
    <div class="sidenav">
      <section class="sidenav-content">
        <h3 class="op-welcome-title">Welcome to OnPoint...</h3>
      </section>
    </div>
  </div>
</clr-main-container>

<!--
  NOTES:
  1. When switching over to paid subscriptions, users will have a 30-day trial window. The window will not start
  until the user has verified their email address.
  2. TO-DO: Put in reasonable error messages when resending verification email fails
-->