import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'aop-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  signinForm: FormGroup;
  email: FormControl;
  password: FormControl;
  rememberMe: FormControl;
  signinErrorTitle: string = null;
  signinErrorMessage: string = null;
  authenticating: boolean = false;
  displayLogoutMessage: boolean = false;

  constructor(private authService: AuthenticationService,
              private router: Router) { }

  ngOnInit() {
    this.createFormControls();
    this.createFormGroup();
    if (this.authService.userLogout) {
      // Set local variable to display message
      this.displayLogoutMessage = true;
      // Set service variable to false so message is not displayed on navigation or refresh
      this.authService.userLogout = false;
    }
    // Debug statement to check user value
    // this.authService.userObservable().subscribe(res => console.log(res));
  }

  createFormControls() {
    this.email = new FormControl('', {
      validators: [
        Validators.required,
        Validators.email
      ],
      updateOn: 'blur'
    });
    this.password = new FormControl('', {
      validators: [
        Validators.required
        // Validators.minLength(8) // Deferring minLength check. Authentication will fail regardless
      ]
      // updateOn: 'blur' // Since only required being checked, validation done on keyUp
    });
    this.rememberMe = new FormControl('');
  }

  createFormGroup() {
    this.signinForm = new FormGroup({
      email: this.email,
      password: this.password,
      rememberMe: this.rememberMe
    });
  }

  formInputState(state: string) {
    this.signinForm.controls['email'][state]();
    this.signinForm.controls['password'][state]();
    this.signinForm.controls['rememberMe'][state]();
  }

  resetForm(arg: string) {
    switch(arg) {
      case "all": {
        this.signinForm.reset();
        break;
      }
      case "credentials": {
        this.email.reset();
        this.password.reset();
        break;
      }
      case "email": {
        this.email.reset();
        break;
      }
      case "password": {
        this.password.reset();
        break;
      }
      default: {
        this.signinForm.reset();
        console.error('Unrecognized argument for reseting form: ' + arg);
        console.log('Valid values are all, credentials, email, or password');
      }
    }
  }

  resetSigninErrors() {
    this.signinErrorTitle = null;
    this.signinErrorMessage = null;
  }

  authenticateUser() {
    console.log('Authenticating user...');
    // Debug statements to check form values prior to submission
    // console.log(`Email: ${this.signinForm.value.email}`);
    // console.log(`Password: ${this.signinForm.value.password}`);
    // console.log(`Remember Me: ${this.signinForm.value.rememberMe}`);
    // Reset signin form authentication errors
    this.resetSigninErrors();
    // Defensive programming to ensure that signin form is valid before authentication
    if (this.signinForm.valid) {
      // Create variable for easier handling of form values
      let email: string = this.signinForm.value.email;
      let password: string = this.signinForm.value.password;
      let rememberMe: boolean = this.signinForm.value.rememberMe ? true : false;
      // Disable form fields and login button, enable spinner
      this.formInputState('disable');
      this.authenticating = true;
      // Debug statements to check form values prior to submission
      // console.log(`Local email: ${email}`);
      // console.log(`Local password: ${password}`);
      // console.log(`Local remember me: ${rememberMe}`);
      this.authService.persistSession(rememberMe).then(() => {
        // If session set properly, then execute the authenticate user function
        return this.authService.authenticateUser(email,password);
      }).then(res => {
      // this.authService.authenticateUser(email,password).then(res => {
        console.log(res);
        this.router.navigate(['/dashboard']);
        // Here's a method to determine if this is the first time a user has logged in. Useful when using
        // federated providers like Facebook and Google
        // console.log('First login? ' + res.additionalUserInfo.isNewUser);
      }).catch(err => {
        console.log(err);
        this.signinErrorTitle = "Authentication failed";
        this.signinErrorMessage = "An invalid email or password was entered. Please check your credentials and try again."
        this.resetForm('credentials');
      }).finally(() => {
        this.formInputState('enable');
        this.authenticating = false;
        // Wait until authentication resolves to reset logout message
        this.displayLogoutMessage = false;
      });
    } else {
      this.signinErrorTitle = "Authentication failed";
      this.signinErrorMessage = "The email address or password is not valid. Please check your credentials and try again.";
    }
  }
}
