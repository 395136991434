<clr-main-container>
  <aop-app-alerts [emailVerified]="(onpointUser | async)?.emailVerified"></aop-app-alerts>
  <aop-navigation></aop-navigation>
  <div class="content-container">
    <main class="content-area">
      <div *ngIf="!(onpointUser | async)?.emailVerified" class="wrapper">
        <p>
          If you have not received an email with a verification link (or if you have misplaced or deleted the email),
          you may use the link at the bottom of the page to have another email verification sent to the email
          address you used to create your account, which is: <b>{{ (onpointUser | async)?.email }}</b>
        </p>
        <p>
          If you do not receive a verification email in your inbox, please check your spam, junk, promotions, or update folders just
          in case. If you are unable to locate the email, or if you receive errors when using the button to resend it, please
          reach out to us via email at support@aumnia.com. We will look into it and do our best to identify and resolve the issue.
        </p>
        <p>
          <button class="btn btn-link" type="button" [disabled]="sendEmailDisabled" (click)="sendEmail()">Send
            Verification Email</button>
        </p>
        <p>
            Once you've successfully verified your email, you will need to log out of OnPoint for the verification to
            take effect. The next time you log in to OnPoint, you will see all of the cool features that you really want
            to use will be unlocked.
        </p>
        <clr-alert *ngIf="emailSuccess" [clrAlertType]="'success'" (clrAlertClosedChange)="onAlertClose()">
          <clr-alert-item>
            <span class="alert-text">
              Verification email successfully sent.
            </span>
          </clr-alert-item>
        </clr-alert>
        <clr-alert *ngIf="emailError" [clrAlertType]="'danger'" (clrAlertClosedChange)="onAlertClose()">
          <clr-alert-item>
            <span class="alert-text">
              An error occurred sending the verification, please close the alert and try again.
              If the problem persists, please contact support for assistance.
            </span>
          </clr-alert-item>
        </clr-alert>
      </div>
      <div *ngIf="(onpointUser | async)?.emailVerified" class="wrapper">
        <p>
          Thank you for verifying your email! You are ready to use OnPoint and all of its features. 
        </p>
        <p>
          Click the button below to get started, or you can navigate to any portion of OnPoint using the 
          navigation links in the header.<br/>
          <button class="btn btn-link" [routerLink]="['/home']">Take me to OnPoint</button>
        </p>
      </div>
    </main>
    <div class="sidenav">
      <section class="sidenav-content">
        <h3 class="op-ev-title">Email Verification</h3>
      </section>
    </div>
  </div>
</clr-main-container>