import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
// From Angular CLI
// import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// import { Observable } from 'rxjs';

import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  
  constructor(private authService: AuthenticationService,
              private router: Router) {}

  // From the Angular CLI
  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  //   return true;
  // }
  // Using Observable because it can take a moment or two for Firebase authentication to spin up
  // on app loading and page refreshes. In other words, cannot use value of currentUser because it
  // does not initialize fast enough
  canActivate(): Observable<boolean> {
    return this.authService.userObservable().pipe(
      // Using take operator so Observable completes after 'taking' one value. Somewhere in the documents
      // it said that the canActivate Observable to complete/finish in order to execute properly.
      take(1),
      // Map user object to boolean. If user exists, returns true, otherwise returns false
      map(user => !!user),
      // loggedIn represents the boolean returned from !!user. If false, then app needs to route
      // the user to the loging page because they are not logged in.
      tap(loggedIn => {
        if(!loggedIn) {
          this.router.navigate(['/login']);
        }
      })
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }
}
