<div class="login-wrapper">
  <form [formGroup]="registrationForm" (ngSubmit)="registerUser()" class="login" novalidate>
    <section class="title">
      <h3 class="welcome">Welcome to</h3>
      Agent OnPoint
      <h5>Create your account</h5>
    </section>
    <div class="login-group">
      <clr-input-container>
        <label for="email">Email Address</label>
        <input type="text" formControlName="email" name="email" clrInput />
        <clr-control-error *clrIfError="'required'">A valid email is required to create an account</clr-control-error>
        <clr-control-error *clrIfError="'email'">Please enter a valid email address</clr-control-error>
      </clr-input-container>
      <clr-password-container>
        <label for="password">Password</label>
        <input type="password" formControlName="password" name="password" clrPassword />
        <clr-control-error *clrIfError="'required'">A password is required to create an account</clr-control-error>
        <clr-control-error *clrIfError="'minlength'">Your password must be at least 8 characters long</clr-control-error>
      </clr-password-container>
      <div class="clr-form-control">
        <label class="clr-control-label" for="confirmPassword">Confirm Password</label>
        <div class="clr-control-container" [ngClass]="{'clr-error': registrationForm.errors?.mismatch || (confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched))}">
          <div class="clr-input-wrapper">
            <input class="clr-input" type="password" formControlName="confirmPassword" name="confirmPassword" />
            <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
            <clr-icon *ngIf="!registrationForm.errors?.unconfirmed && (confirmPassword.valid && password.valid)" class="clr-validate-icon success"
              shape="check-circle"></clr-icon>
          </div>
          <span *ngIf="confirmPassword.errors?.required && (confirmPassword.dirty || confirmPassword.touched)" class="clr-subtext">Confirm
            password to continue</span>
        </div>
      </div>
      <div class="clr-form-control">
        <label class="clr-control-label" for="registrationCode">Registration Code</label>
        <div class="clr-control-container" [ngClass]="{'clr-error': registrationCode.invalid && (registrationCode.dirty || registrationCode.touched)}">
          <div class="clr-input-wrapper">
            <input class="clr-input" type="text" formControlName="registrationCode" name="registrationCode" />
            <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
            <!-- <clr-icon *ngIf="!registrationForm.errors?.unconfirmed" class="clr-validate-icon success"
              shape="check-circle"></clr-icon> -->
          </div>
          <span *ngIf="registrationCode.errors?.invalidCode && (registrationCode.dirty || registrationCode.touched)" class="clr-subtext">Invalid registration code</span>
        </div>
      </div>
      <div *ngIf="registrationErrorTitle">
        <p class="error active">
          {{ registrationErrorTitle }}
        </p>
        <p *ngIf="registrationErrorMessage" class="registration-error-message">
          {{ registrationErrorMessage }}
        </p>
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="!registrationForm.valid">
        <span *ngIf="!registering">Next</span>
        <span *ngIf="registering" class="spinner spinner-inline"></span><span *ngIf="registering" style="text-transform: none;">&nbsp;Creating account</span>
      </button>
      <p class="text-center">
        Already have an Agent OnPoint account?<br />
        <a routerLink="/login">Login here</a>
      </p>
    </div>
  </form>
</div>