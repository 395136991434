import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SigninComponent } from './signin/signin.component';
import { RegisterComponent } from './register/register.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { WelcomeComponent } from './welcome/welcome.component';

import { LoginGuard } from './guards/login.guard';
import { AuthGuard } from './guards/auth.guard';
import { EmailVerifiedGuard } from './guards/email-verified.guard';

const routes: Routes = [
  { path: 'login', component: SigninComponent, canActivate: [LoginGuard] },
  { path: 'register', component: RegisterComponent, canActivate: [LoginGuard] },
  { path: 'verification', component: EmailVerificationComponent, canActivate: [EmailVerifiedGuard] },
  { path: 'welcome', component: WelcomeComponent, canActivate: [EmailVerifiedGuard] },
  { path: 'signin', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
