import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'aop-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(private authService: AuthenticationService) { }

  // Leaving this is as backup. Template implementation is {{ (onpointUser | async).email }}
  onpointUser: Observable<any> = this.authService.userObservable();

  // Static value, works better since we're not managing subscriptions for this simple use case
  // Removing static value set since @angular/fire v6 does not support it
  // onPointCurrentUser: any;
  registrationEmail: string;
  sendEmailDisabled: boolean = false;
  emailSuccess: boolean = false;
  emailError: boolean = false;

  ngOnInit() {
  }

  logout() {
    this.authService.logout('/login');
  }

  sendEmail() {
    this.sendEmailDisabled = true;
    this.emailSuccess = false;
    this.emailError = false;
    this.authService.sendVerificationEmail().then(() => {
      this.emailSuccess = true;
    }).catch(err => {
      this.emailError = true;
      console.error('An error occurred sending verification email');
      console.log(err);
    });
  }

  onAlertClose() {
    this.sendEmailDisabled = false;
  }
}

// TO-DO: Consider moving sendEmail to authentication service, and move alerts in html to shared component.
//        When using federated identity providers, email will not need to be verified. Will need a check that
//        bypasses the email verification process and just welcomes the user.

// To get access to all features, user will still have to verify email in the dev/beta environment.
// The server, if necessary, will do the check to make sure that a local user is created in the database
// using middleware to do the check.
// Provide message that the user needs to verify their email address if they haven't done so already.
