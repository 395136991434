import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { ListContact } from '../../interfaces/list-contact';

@Component({
  selector: 'aop-contacts-table',
  templateUrl: './contacts-table.component.html',
  styleUrls: ['./contacts-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsTableComponent implements OnInit {
  @Input() contactsList: ListContact[];
  @Input() status: string;
  @Input() loading: boolean;
  @Output() outContactTableEvt = new EventEmitter<any>();
  modalActive: boolean = false;
  deleteConfirmResponse = new Subject<boolean>();

  constructor() { }

  ngOnInit() {
  }

  contactAction(tableContact, tableAction) {
    const contactTableEvt = {
      contact: tableContact,
      action: tableAction,
    }
    this.outContactTableEvt.emit(contactTableEvt);
  }

  // Method called from anchor links in table that prevents bubbling
  tableLinkHandler(e) {
    e.stopPropagation();
  }

  deleteContact(contact) {
    this.modalActive = true;
    this.deleteConfirmResponse.pipe(
      finalize(() => this.modalActive = false),
      take(1)
    ).subscribe(resp => {
      if (resp) {
        this.contactAction(contact, 'remove');
      }
    });
  }

  confirmDelete() {
    this.deleteConfirmResponse.next(true);
  }

  cancelDelete() {
    this.deleteConfirmResponse.next(false);
  }
  
  modalChange(event: any) {
    if (event === false) {
      this.deleteConfirmResponse.next(false);
    }
  }

  // May need to set the event at the top level to check if the event target hits the modal button
  // If that is hit, need to emit a subject that says the delete has been cancelled.
  // modalResponse(event: any) {
    // this.modalActive = false;
    // const modalBtnText = event.target.textContent.trim().toLowerCase();
    // console.log(modalBtnText);
    // if (modalBtnText === "ok") {
    //   this.deleteConfirmResponse.next(true);
    // } else if (modalBtnText === "cancel") {
    //   this.deleteConfirmResponse.next(false);
    // }
    // console.log(event);
  // }
}

/*
1.  Format phone numbers into (XXX) YYY-ZZZZ format (later to add library for
    formatting by international standards)
2.  Add warning/error icons to contacts that are missing email or phone numbers

If doing batch selection, need the selection variable initialized in ts - https://github.com/vmware/clarity/issues/704
*/
