<clr-header>
  <div class="branding">
    <a routerLink="/home" class="nav-link">
      <clr-icon shape="block"></clr-icon>
      <span class="title">Agent OnPoint</span>
    </a>
  </div>
  <div class="header-nav" [clr-nav-level]="1">
    <a routerLink="/dashboard" routerLinkActive="active" class="nav-link"><span class="nav-text">Dashboard</span></a>
    <a class="nav-link aop-pn-disabled"><span class="nav-text">Reminders</span></a>
    <a routerLink="/contacts" routerLinkActive="active" class="nav-link"><span class="nav-text">Contacts</span></a>
    <a class="nav-link aop-pn-disabled"><span class="nav-text">Activities</span></a>
    <a class="nav-link aop-pn-disabled"><span class="nav-text">Goals</span></a>
    <a class="nav-link aop-pn-disabled"><span class="nav-text">Resources</span></a>
  </div>
  <div class="header-actions">
    <clr-dropdown>
      <button class="nav-icon" clrDropdownTrigger>
        <clr-icon shape="user"></clr-icon>
        <clr-icon shape="caret down"></clr-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <!-- <a clrDropdownItem>Profile</a>
        <a clrDropdownItem>Settings</a> -->
        <span class="aop-pn-dropdown-disabled" clrDropdownItem>Profile</span>
        <span class="aop-pn-dropdown-disabled" clrDropdownItem>Settings</span>
        <a clrDropdownItem (click)="logout()">Logout</a>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>
</clr-header>