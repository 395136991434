import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class EmailVerifiedGuard implements CanActivate {
  // From Angular CLI -> not sure about next, state usage in Guard
  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }
  
  constructor (private authService: AuthenticationService,
               private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.userObservable().pipe(
      take(1),
      map(user => {
        // If user is not logged in, then send to login page
        if (!user) {
          this.router.navigate(['/login']);
          return false;
        }
        // If user email is verified, send to home page, otherwise, show welcome page
        if (user.emailVerified) {
          this.router.navigate(['/home']);
          return false;
        } else {
          return true;
        }
      })
    )
  }
}
