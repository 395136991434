import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { User } from 'firebase';
import { auth } from 'firebase/app';
import { Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  // Boolean to display logout message to user on the signin page
  public userLogout: boolean = false;

  constructor(private afAuth: AngularFireAuth,
              private router: Router) { }

  createUser(email: string, password: string): Promise<any> {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  persistSession(value: boolean): Promise<any> {
    if (value) {
      return this.afAuth.setPersistence(auth.Auth.Persistence.LOCAL);
    } else {
      return this.afAuth.setPersistence(auth.Auth.Persistence.SESSION);
    }
  }

  authenticateUser(email: string, password: string): Promise<any> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  sendVerificationEmail(): Promise<any> {
    // return this.afAuth.currentUser.sendEmailVerification();
    return this.afAuth.currentUser.then((user) => user.sendEmailVerification());
  }

  googleLogin(): Promise<any> {
    let provider = new auth.GoogleAuthProvider();
    return this.afAuth.signInWithPopup(provider);
  }

  // Removed as part of upgrade to @angular/fire v6
  // Depending on evolution, this property may come back
  // currentUser() {
  //   return this.afAuth.currentUser;
  // }

  userObservable(): Observable<any> {
    return this.afAuth.user;
  }

  authStateObservable() {
    return this.afAuth.authState;
  }

  idTokenObservable() {
    return this.afAuth.idToken;
  }

  idTokenResultObservable() {
    return this.afAuth.idTokenResult;
  }

  logout(logoutRoute: string) {
    // Could simply return the afAuth promise, but then the code below the comment would get duplicated
    // anywhere a logout function is implemented.
    // return this.afAuth.auth.signOut();
    this.afAuth.signOut().then(() => {
      // Set service variable to display logout message on login page
      this.userLogout = true;
      this.router.navigate([logoutRoute]);
    }).catch(err => {
      console.error('An error occurred during logout.');
      console.log(err.code);
    })
  }
}

// TO-DOs (new method, as of 9/20/19):
// 1. Implement interceptor for HTTP method that adds Firebase token to all route requiring
//    authentication, including the service to create a local user in the database
// 2. Implement service to create local user in database (returns user profile information, no id)
// 3. Have a value that checks for the emailIsVerified field - this field is key to unlocking 
//    the features of the OnPoint app for the user 

// TO-DOs (old method, as of 9/17/19):
// 1. Add in service that returns the local user value
// 2. Add service to retrieve local user value (used when empty)
// 3. Will need to create observable that subscribes to the Angular Fire user value.
//    When it is null, clear out the local user value, when the AF value is populated, populate the local user
// 4. Alternatively, have the service manage itself, meaning the service just checks to insure
//    there is a local user in the table that corresponds to the AF user. If so, then the save
//    functions and other items are enabled. Otherwise, the user either has to verify their email, or
//    they need to reload the app to have it check again.
// 5. In the second case, there still needs to be an observable set that checks every time
//    the user logs out and logs back in, in case the user logs back in as a different user.
