import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';

import { AuthenticationService } from '../../authentication/authentication.service';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  selector: 'aop-on-point-container',
  templateUrl: './on-point-container.component.html',
  styleUrls: ['./on-point-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnPointContainerComponent implements OnInit {

  constructor(private authService: AuthenticationService,
              private notificationsService: NotificationsService) { }

  userEmailVerified: Observable<any>;
  showNotification: Observable<boolean>;
  animateNotification: Observable<boolean>;
  notificationType: Observable<string>;
  notificationMessage: Observable<string>;
  modalState: boolean;
  // hideNotification = new BehaviorSubject<boolean>(true);
  pseudoToast;

  ngOnInit() {
    // Consider switching from observable to currentUser().emailVerified property
    this.userEmailVerified = this.authService.userObservable().pipe(
      map(user => {
        if (user) {
          return user.emailVerified;
        } else {
          return false;
        }
      })
    );
    // multiple subscriptions in template, using share to prevent multiple subscriptions
    this.showNotification = this.notificationsService.showNotification().pipe(
      // User shareReplay in case there is a timing difference in the observables in the template
      // Per suggestion here - https://medium.com/@mikesnare/angular-async-pipes-beware-the-share-bcc9c1cd849d
      tap(show => {
        if (show) {
          this.modalState = show;
        } else {
          setTimeout(() => {
            this.modalState = show;
          }, 1000);
        }
      })
    );
    this.notificationType = this.notificationsService.notificationType();
    this.notificationMessage = this.notificationsService.notificationMessage();
  }

  // testBehavior() {
  //   clearTimeout(this.pseudoToast);
  //   this.hideNotification.next(false);
  //   this.pseudoToast = setTimeout(() => {
  //     console.log('Here!');
  //     this.hideNotification.next(true);
  //   }, 20000);
  // }

  hideNotificationChange(hidden) {
    if (hidden) {
      // console.log('There!');
      // clearTimeout(this.pseudoToast);
      // this.hideNotification.next(true);
      this.notificationsService.disableNotification();
    }
  }
}
