// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCBXqP9i5yM72GH91h2oxacmj6EjX1sDRg",
    authDomain: "agent-onpoint-dev.firebaseapp.com",
    databaseURL: "https://agent-onpoint-dev.firebaseio.com",
    projectId: "agent-onpoint-dev",
    storageBucket: "agent-onpoint-dev.appspot.com",
    messagingSenderId: "904484795440"
  },
  baseUrl: {
    api: 'https://p63io2ji5g.execute-api.us-west-2.amazonaws.com/dev/v1'
  },
  registrationCodes: ['onpoint-preview', 'aumnia'],
  version: require('../../package.json').version
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
