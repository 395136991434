import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  
  constructor(private authService: AuthenticationService,
              private router: Router) { }
  
  canActivate(): Observable<boolean> {
    return this.authService.userObservable().pipe(
      // Grab first value emitted by Observable
      take(1),
      // If logged in, user object will not be null, so return to current route
      map(user => {
        if (user) {
          this.router.navigate(['/home']);
        }
        // Return true of if user not logged in (user is null), false if user is logged in
        return !user;
      })
    );
  }
}
